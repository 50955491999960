import { sitePropertyActionTypes } from "actions/sites_page";

export const sitePropertyFormReducerDefaultState = {
  shown: false,
  editing: false,
  saving: false,
  values: {
    name: "",
    identifier: "",
    data_type: "",
    required: false,
    single_value_options: [],
  },
  errors: {},
};

export const SitePropertyFormReducer = (
  state = sitePropertyFormReducerDefaultState,
  action
) => {
  switch (action.type) {
    case sitePropertyActionTypes.OPEN_NEW_SITE_PROPERTY_FORM: {
      const newState = {
        ...state,
        ...sitePropertyFormReducerDefaultState,
        shown: true,
      };

      return newState;
    }

    case sitePropertyActionTypes.OPEN_EDIT_SITE_PROPERTY_FORM: {
      const siteProperty = action.siteProperty;
      const newState = {
        ...state,
        shown: true,
        editing: true,
        sitePropertyId: siteProperty.id,
        values: {
          ...state.values,
          name: siteProperty.name || "",
          identifier: siteProperty.identifier || "",
          data_type: siteProperty.data_type || "",
          single_value_options: siteProperty.single_value_options,
        },
        errors: {},
      };

      return newState;
    }

    case sitePropertyActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE: {
      const newState = { ...state, saving: true };
      return newState;
    }

    case sitePropertyActionTypes.SITE_PROPERTY_FORM_ERROR: {
      const newState = { ...state, saving: false, errors: action.errors };
      return newState;
    }

    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        ...sitePropertyFormReducerDefaultState,
        shown: true,
      };

      return newState;
    }

    case sitePropertyActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS:
    case sitePropertyActionTypes.CLOSE_SITE_PROPERTY_FORM: {
      const newState = {
        ...state,
        shown: false,
        editing: false,
        saving: false,
      };
      return newState;
    }

    case sitePropertyActionTypes.SITE_PROPERTY_FORM_VALUE_CHANGE: {
      let options = state.values.single_value_options;
      if (
        state.values.data_type == "single_value" &&
        action.fieldName == "data_type"
      ) {
        options = [];
      }

      const newState = {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: action.value,
          single_value_options: options,
        },
      };
      return newState;
    }

    case sitePropertyActionTypes.SITE_PROPERTY_FORM_OPTION_VALUE_CHANGE: {
      let option = { ...state.values.single_value_options[action.optionIndex] };
      option[action.fieldName] = action.value;

      const newState = {
        ...state,
        values: {
          ...state.values,
          single_value_options: state.values.single_value_options.map(
            (existingOption, index) => {
              if (index == action.optionIndex) {
                return option;
              } else {
                return existingOption;
              }
            }
          ),
        },
      };
      return newState;
    }

    case sitePropertyActionTypes.ADD_SITE_PROPERTY_SINGLE_VALUE_OPTION: {
      let options = [...state.values.single_value_options];
      options.push({ name: "" });

      const newState = {
        ...state,
        values: {
          ...state.values,
          single_value_options: options,
        },
      };
      return newState;
    }

    case sitePropertyActionTypes.REMOVE_SITE_PROPERTY_SINGLE_VALUE_OPTION: {
      let options = [...state.values.single_value_options];

      let optionToRemove = options[action.optionIndex];
      if (optionToRemove.id) {
        optionToRemove.marked_for_destruction = true;
        options = options.map((option, index) => {
          if (index == action.optionIndex) {
            return optionToRemove;
          } else {
            return option;
          }
        });
      } else {
        options.splice(action.optionIndex, 1);
      }

      const newState = {
        ...state,
        values: {
          ...state.values,
          single_value_options: options,
        },
      };
      return newState;
    }
  }

  return state;
};
